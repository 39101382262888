/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { findBySlug } from "../helpers"
import kebabCase from "lodash/kebabCase"
import get from "lodash/get"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import CTA from "../components/cta"
import Column from "../components/column"
import ContactForm from "../components/contact-form"
import Container from "../components/container"
import Page from "../components/page"
import PageLink from "../components/page-link"
import Row from "../components/row"
import Text from "../components/text"

const ContactUs = ({ data }) => {
  console.log("asd", data)
  const renderContactForm = () => {
    const contactFormBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "contact-us-form-module",
    })
    return (
      <Container customStyle={{ p: [3, 3, 6] }}>
        <Row customStyle={{ pb: 4 }}>
          <Column customStyle={{ maxWidth: 500 }}>
            <Text type="h1">{contactFormBladePatternData.title}</Text>
          </Column>
        </Row>
        <Row customStyle={{ pb: 5 }}>
          <Column customStyle={{ maxWidth: 300 }}>
            <Text>{contactFormBladePatternData.description.description}</Text>
          </Column>
        </Row>
        <ContactForm customStyle={{ px: 0 }}></ContactForm>
      </Container>
    )
  }
  const renderCTAs = () => {
    const contactCtasBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "contact-us-ctas",
    }).contentCards

    return (
      <div
        id="custom-efficient-world-class-design"
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container>
          <Row>
            <Column size={[12, 12, 3]}>
              <Text type="h4" customStyle={{ mb: theme => [0, 0, 3] }}>
                {contactCtasBladePatternData[0].title}
              </Text>
              <div sx={{ width: "75%" }}>
                {contactCtasBladePatternData[0].description &&
                  documentToReactComponents(
                    contactCtasBladePatternData[0].description.json,
                    {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <Text
                            customStyle={{
                              mt: 0,
                            }}
                          >
                            {children}
                          </Text>
                        ),
                      },
                    }
                  )}
                <CTA
                  link={""}
                  type="secondary"
                  customStyle={{
                    mb: [3, 3, 0],
                    mt: 3,
                  }}
                >
                  {contactCtasBladePatternData[0].ctaTitle}
                </CTA>
              </div>
            </Column>
            <Column size={[12, 12, 3]}>
              <Text type="h4" customStyle={{ mb: theme => [0, 0, 3] }}>
                {contactCtasBladePatternData[1].title}
              </Text>
              <div sx={{ width: "75%" }}>
                {contactCtasBladePatternData[1].description &&
                  documentToReactComponents(
                    contactCtasBladePatternData[1].description.json,
                    {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <Text
                            customStyle={{
                              mt: 0,
                            }}
                          >
                            {children}
                          </Text>
                        ),
                      },
                    }
                  )}
                <CTA
                  link={""}
                  type="secondary"
                  customStyle={{
                    mb: [3, 3, 0],
                    mt: 3,
                  }}
                >
                  {contactCtasBladePatternData[1].ctaTitle}
                </CTA>
              </div>
            </Column>
            <Column size={[12, 12, 3]}>
              <Text type="h4">{contactCtasBladePatternData[2].title}</Text>
              <PageLink
                customStyle={{ color: "primary", my: 3 }}
                link={get(
                  contactCtasBladePatternData[2],
                  "ctaDestination.ctaDestination",
                  "/"
                )}
                text={`${contactCtasBladePatternData[2].ctaTitle} >`}
                type="internal"
              />
              <Text type="h4">{contactCtasBladePatternData[3].title}</Text>
              <PageLink
                customStyle={{ color: "primary", my: 3 }}
                link={get(
                  contactCtasBladePatternData[3],
                  "ctaDestination.ctaDestination",
                  "/"
                )}
                text={`${contactCtasBladePatternData[3].ctaTitle} >`}
                type="internal"
              />
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderHeadquarters = () => {
    const contactHeadquartersBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "contact-us-headquarters-and-factory",
    })
    const contentCards = contactHeadquartersBladePatternData.contentCards
    const image = contactHeadquartersBladePatternData.desktopImage

    return (
      <Container>
        <Row
          customStyle={{
            py: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              theme.spacing.vertical.md,
            ],
          }}
        >
          <Column
            size={[12, 12, 7]}
            customStyle={{
              mb: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                0,
              ],
            }}
          >
            <div>
              <img
                alt={image.title}
                sx={{ width: "100%" }}
                src={image.file.url}
              />
            </div>
          </Column>
          <Column
            size={[12, 12, 5]}
            customStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {contentCards.length &&
              contentCards.map(card => {
                return (
                  <div
                    key={kebabCase(card.title)}
                    sx={{ width: "100%", alignSelf: "flex-start" }}
                  >
                    <Text type="h4">{card.title}</Text>
                    {card.description &&
                      documentToReactComponents(card.description.json, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => (
                            <Text
                              customStyle={{
                                color: "muted",
                                mt: 0,
                              }}
                            >
                              {children}
                            </Text>
                          ),
                        },
                      })}
                    <PageLink
                      customStyle={{ color: "primary", my: 3 }}
                      link={get(card, "ctaDestination.ctaDestination", "/")}
                      text={`${card.ctaTitle} >`}
                      type="internal"
                    />
                  </div>
                )
              })}
          </Column>
        </Row>
      </Container>
    )
  }

  return (
    <Page data={data.contentfulPage}>
      {renderContactForm()}
      {renderCTAs()}
      {renderHeadquarters()}
    </Page>
  )
}

export default ContactUs

export const query = graphql`
  query contactUsPageQuery {
    contentfulPage(slug: { eq: "contact-us" }) {
      ...PageData
      ...BladePatternData
    }
  }
`
