/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import get from "lodash/get"
import {
  Radio,
  Label,
  Input,
  Select,
  Textarea,
  Button,
  Spinner,
} from "@theme-ui/components"
import { useForm } from "react-hook-form"

import Container from "../components/container"
import Row from "../components/row"
import Column from "../components/column"
import Text from "../components/text"

const ContactForm = ({ customStyle }) => {
  const [stepState, setStepState] = React.useState({
    step: -2,
    personType: null,
    basicDetailsSet: false,
    sending: false,
    sent: false,
    data: {},
  })
  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onChange",
    submitFocusError: false,
  })
  const watches = watch(["form"])
  const doNextStep = data => {
    if (!stepState.basicDetailsSet) {
      setStepState({ ...stepState, basicDetailsSet: true })
    } else if (stepState.personType) {
      let nextStep = stepState.step + 1
      while (
        nextStep < routes[stepState.personType].questions.length &&
        !validateQuestionCondition(
          routes[stepState.personType].questions[nextStep],
          data
        )
      ) {
        nextStep++
      }
      setStepState({ ...stepState, step: nextStep })
    }
  }
  const changePersonType = type => {
    setStepState({ ...stepState, step: 0, personType: type })
  }
  const onSubmit = async data => {
    if (isLastQuestion && data.form) {
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
      setStepState({ ...stepState, sending: true, data })
    } else {
      doNextStep(data)
    }
  }
  const renderSelectionButton = (type, currentType, handler) => {
    return (
      <Button
        variant="forms.buttonSelection"
        onClick={() => {
          handler(type)
        }}
        type="button"
        sx={{
          bg: () => (currentType === type ? "primary" : "secondary"),
        }}
      >
        {type}
      </Button>
    )
  }
  const renderBasicQuestions = () => (
    <Row customStyle={{ mb: 4 }}>
      <Column size={[12, 12, 6, 4]}>
        <Label sx={{ mb: 1 }}>First Name</Label>
        <Input
          name="form.basicDetails.firstName"
          ref={register({
            required: "required",
          })}
          variant={
            get(errors, "form.basicDetails.firstName")
              ? "forms.inputError"
              : "forms.input"
          }
        />
      </Column>
      <Column size={[12, 12, 6, 4]}>
        <Label sx={{ mb: 1 }}>Last Name</Label>
        <Input
          name="form.basicDetails.lastName"
          ref={register({
            required: "required",
          })}
          variant={
            get(errors, "form.basicDetails.lastName")
              ? "forms.inputError"
              : "forms.input"
          }
        />
      </Column>
      <Column size={[12, 12, 6, 4]}>
        <Label sx={{ mb: 1 }}>Email Address</Label>
        <Input
          name="form.basicDetails.email"
          ref={register({
            required: "required",
            pattern: /^\S+@\S+$/i,
          })}
          variant={
            get(errors, "form.basicDetails.email")
              ? "forms.inputError"
              : "forms.input"
          }
        />
      </Column>
    </Row>
  )
  const renderQuestion = question => {
    let name = `form._${question.id}`
    return (
      <Row customStyle={{ mb: 4 }}>
        <Column size={[12, 12, 12]}>
          <Text type="h3" customStyle={{ mb: 2 }}>
            {question.question}
          </Text>
        </Column>
        <Column size={[12, 12, 12]}>
          {question.type === "input" ? (
            <Input
              name={name}
              {...(question.required
                ? {
                    ref: register({
                      required: "required",
                    }),
                    variant: get(errors, name)
                      ? "forms.inputError"
                      : "forms.input",
                  }
                : { ref: register({}) })}
            />
          ) : null}
          {question.type === "location" ? (
            <Row>
              <Column size={[12, 12, 12, 12]}>
                <Label sx={{ mb: 1 }}>Country</Label>
                <Select
                  name={`${name}.country`}
                  ref={register({
                    required: "required",
                  })}
                  defaultValue="US"
                >
                  <option value="AF">Afghanistan</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="CI">Cote D'Ivoire</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and Mcdonald Islands</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic Of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libyan Arab Jamahiriya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia, Federated States of</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="AN">Netherlands Antilles</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="KP">
                    Democratic People's Republic of Korea
                  </option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Reunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="SH">Saint Helena</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="CS">Serbia and Montenegro</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">
                    South Georgia and the South Sandwich Islands
                  </option>
                  <option value="KR">Korea, Republic of</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="CD">
                    Congo, The Democratic Republic of the
                  </option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="VI">Virgin Islands, U.S.</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM">
                    United States Minor Outlying Islands
                  </option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Viet Nam</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara </option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                  <option value="AX">Åland Islands</option>
                </Select>
              </Column>
              <Column size={[12, 12, 12, 12]}>
                <Label sx={{ mb: 1 }}>Street</Label>
                <Input
                  name={`${name}.address`}
                  ref={register({})}
                  variant={
                    get(errors, `${name}.address`)
                      ? "forms.inputError"
                      : "forms.input"
                  }
                />
              </Column>
              <Column size={[12, 12, 6, 4]}>
                <Label sx={{ mb: 1 }}>City</Label>
                <Input
                  name={`${name}.city`}
                  ref={register({
                    required: "required",
                  })}
                  variant={
                    get(errors, `${name}.city`)
                      ? "forms.inputError"
                      : "forms.input"
                  }
                />
              </Column>
              <Column size={[12, 12, 6, 4]}>
                <Label sx={{ mb: 1 }}>State</Label>
                <Input
                  name={`${name}.state`}
                  ref={register({
                    required: "required",
                  })}
                  variant={
                    get(errors, `${name}.state`)
                      ? "forms.inputError"
                      : "forms.input"
                  }
                />
              </Column>
              <Column size={[12, 12, 6, 4]}>
                <Label sx={{ mb: 1 }}>Zip</Label>
                <Input
                  name={`${name}.zip`}
                  ref={register({
                    required: "required",
                    pattern: /^[0-9].+$/i,
                  })}
                  variant={
                    get(errors, `${name}.zip`)
                      ? "forms.inputError"
                      : "forms.input"
                  }
                />
              </Column>
            </Row>
          ) : null}
          {question.type === "textarea" ? (
            <Textarea
              name={name}
              {...(question.required
                ? {
                    ref: register({
                      required: "required",
                    }),
                    variant: get(errors, name)
                      ? "forms.textareaError"
                      : "forms.textarea",
                  }
                : { ref: register({}) })}
            />
          ) : null}
          {question.type === "select" ? (
            <Select
              defaultValue={question.defaultAnswer}
              name={name}
              ref={register({ required: true })}
            >
              {question.answers.map((answer, index) => (
                <option key={index}>{answer}</option>
              ))}
            </Select>
          ) : null}
          {question.type === "radio"
            ? question.answers.map((option, index) => (
                <Label key={index}>
                  <Radio
                    name={name}
                    value={option}
                    {...(option === question.defaultAnswer
                      ? { defaultChecked: "checked" }
                      : {})}
                    ref={register({ required: true })}
                  />
                  {option}
                </Label>
              ))
            : null}
        </Column>
      </Row>
    )
  }
  const renderPersonTypeSelector = () => (
    <Row customStyle={{ mb: 4 }}>
      <Column size={[12, 12, 12]}>
        <Text type="h3" customStyle={{ mb: 2 }}>
          What best describes you?
        </Text>
      </Column>
      <Row
        customStyle={{
          mx: 1,
        }}
      >
        {Object.keys(routes).map((type, index) => (
          <div key={index} sx={{ mx: 1 }}>
            {renderSelectionButton(
              type,
              stepState.personType,
              changePersonType
            )}
          </div>
        ))}
      </Row>
    </Row>
  )

  const routes = {
    Homebuyer: {
      leadCategory: "Homebuyer",
      questions: [
        {
          id: "hb1",
          question: "Where are you hoping to build?",
          fieldName: "Initial+Land+Status",
          required: true,
          type: "select",
          answers: [
            "A lot that I own",
            "A lot that I'm in the process of buying",
            "I'm still looking for the right lot",
            "Not sure (I'm not yet looking)",
          ],
        },
        {
          id: "hb2",
          question: "Where are you hoping to buy land?",
          required: true,
          type: "location",
          conditionQuestion: "hb1",
          conditionAnswers: ["I'm still looking for the right lot"],
        },
        {
          id: "hb3",
          question: "Great! Where's the lot?",
          required: true,
          type: "location",
          conditionQuestion: "hb1",
          conditionAnswers: [
            "A lot that I own",
            "A lot that I'm in the process of buying",
          ],
        },
        {
          id: "hb4",
          question: "When do you hope to start building?",
          fieldName: "Initial+Timeline",
          required: true,
          type: "radio",
          answers: [
            "Immediately",
            "Within the next 12 months",
            "A year or more in the future",
          ],
        },
        {
          id: "hb5",
          question: "Have you established a budget for your project?",
          fieldName: "Initial+Budget+Established",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
        },
        {
          id: "hb6",
          question:
            "What are you hoping to find out? Please include what size and kind of home you’re considering.",
          fieldName: "Contact+Form+Info",
          required: true,
          type: "textarea",
        },
        {
          id: "hb7",
          question: "How did you hear about us?",
          fieldName: "How+They+Heard+About+Us",
          required: false,
          type: "textarea",
        },
        {
          id: "hb8",
          question:
            "Receive updates and tour invites? (Sent roughly once a month.)",
          fieldName: "Email+Opt+In",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
        },
      ],
    },
    "Fire Victim": {
      leadCategory: "Wildfire Rebuild",
      questions: [
        {
          id: "fv1",
          question:
            "We are very sorry for your loss, and hope we can help you rebuild as quickly as possible. Where will you be re-building?",
          fieldName: "Contact+Form+Info",
          required: true,
          type: "location",
        },
        {
          id: "fv2",
          question:
            "Where are you in your re-building process, and what are your goals for working with us?",
          fieldName: "Contact+Form+Info",
          required: true,
          type: "textarea",
        },
        {
          id: "fv3",
          question: "How did you hear about us?",
          fieldName: "How+They+Heard+About+Us",
          required: false,
          type: "textarea",
        },
        {
          id: "fv4",
          question:
            "Receive updates and tour invites? (Sent roughly once a month.)",
          fieldName: "Email+Opt+In",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
        },
      ],
      hiddenFields: [
        "Initial+Land+Status=Own+Lot",
        "Initial+Timeline=Immediately",
      ],
    },
    Architect: {
      leadCategory: "Architect",
      questions: [
        {
          id: "a1",
          question: "Company",
          fieldName: "Company",
          required: true,
          type: "input",
        },
        {
          id: "a2",
          question: "Phone",
          fieldName: "Phone",
          required: true,
          type: "input",
        },
        {
          id: "a3",
          question: "Do you have a specific project to discuss?",
          fieldName: "Initial+Project+to+Discuss",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
        },
        {
          id: "a4",
          question: "Great! Where will the project be built?",
          required: true,
          type: "location",
          conditionQuestion: "a3",
          conditionAnswers: ["Yes"],
        },
        {
          id: "a5",
          question:
            "Where are the majority of your projects? (This just helps us direct your inquiry.)",
          required: true,
          type: "location",
          conditionQuestion: "a3",
          conditionAnswers: ["No"],
        },
        {
          id: "a6",
          question: "Do you have drawings to review?",
          fieldName: "Initial+Drawings+Existence",
          required: false,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
          conditionQuestion: "a3",
          conditionAnswers: ["Yes"],
        },
        {
          id: "a7",
          question: "Is there an established budget for this project?",
          fieldName: "Initial+Budget+Established",
          required: false,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
          conditionQuestion: "a3",
          conditionAnswers: ["Yes"],
        },
        {
          id: "a8",
          question: "How can we help you?",
          fieldName: "Contact+Form+Info",
          required: true,
          type: "textarea",
        },
        {
          id: "a9",
          question: "How did you hear about us?",
          fieldName: "How+They+Heard+About+Us",
          required: false,
          type: "textarea",
        },
        {
          id: "a10",
          question:
            "Receive updates and tour invites? (Sent roughly once a month.)",
          fieldName: "Email+Opt+In",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
        },
      ],
    },
    Developer: {
      leadCategory: "Developer",
      questions: [
        {
          id: "d1",
          question: "Company",
          fieldName: "Company",
          required: true,
          type: "input",
        },
        {
          id: "d2",
          question: "Phone",
          fieldName: "Phone",
          required: true,
          type: "input",
        },
        {
          id: "d3",
          question: "Do you have a specific project to discuss?",
          fieldName: "Initial+Project+to+Discuss",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
        },
        {
          id: "d4",
          question: "Great! Where will the project be built?",
          required: true,
          type: "location",
          conditionQuestion: "d3",
          conditionAnswers: ["Yes"],
        },
        {
          id: "d5",
          question: "Where are the majority of your projects?",
          required: true,
          type: "location",
          conditionQuestion: "d3",
          conditionAnswers: ["No"],
        },
        {
          id: "d6",
          question: "Do you own this land?",
          fieldName: "Initial+Land+Status",
          required: false,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
          conditionQuestion: "d3",
          conditionAnswers: ["Yes"],
        },
        {
          id: "d7",
          question: "Have you secured financing for this project?",
          fieldName: "Initial+Financing+Secured",
          required: false,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
          conditionQuestion: "d3",
          conditionAnswers: ["Yes"],
        },
        {
          id: "d8",
          question: "When do you hope to start building?",
          fieldName: "Initial+Timeline",
          required: true,
          type: "radio",
          answers: [
            "Immediately",
            "Within the next 12 months",
            "A year or more in the future",
          ],
        },
        {
          id: "d9",
          question: "Have you established a budget for your project?",
          fieldName: "Initial+Budget+Established",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
        },
        {
          id: "d10",
          question: "What’s your project budget?",
          fieldName: "Initial+Budget+Established",
          required: true,
          type: "input",
          conditionQuestion: "d3",
          conditionAnswers: ["Yes"],
        },
        {
          id: "d11",
          question: "What’s your average project budget?",
          fieldName: "Initial+Target+Price+Per+SF",
          required: true,
          type: "input",
          conditionQuestion: "d3",
          conditionAnswers: ["No"],
        },
        {
          id: "d12",
          question: "Do you have drawings for this project?",
          fieldName: "Initial+Drawings+Existence",
          required: false,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
          conditionQuestion: "d3",
          conditionAnswers: ["Yes"],
        },
        {
          id: "d11",
          question: "How can we help you?",
          fieldName: "Contact+Form+Info",
          required: true,
          type: "textarea",
        },
        {
          id: "d12",
          question: "How did you hear about us?",
          fieldName: "How+They+Heard+About+Us",
          required: false,
          type: "textarea",
        },
        {
          id: "d13",
          question:
            "Receive updates and tour invites? (Sent roughly once a month.)",
          fieldName: "Email+Opt+In",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
        },
      ],
    },
    Vendor: {
      leadCategory: "Vendor",
      questions: [
        {
          id: "v1",
          question: "Company",
          fieldName: "Company",
          required: true,
          type: "input",
        },
        {
          id: "v2",
          question: "Phone",
          fieldName: "Phone",
          required: true,
          type: "input",
        },
        {
          id: "v3",
          question: "How did you hear about us?",
          fieldName: "How+They+Heard+About+Us",
          required: false,
          type: "textarea",
        },

        {
          id: "v4",
          question: "How might we work together?",
          fieldName: "Contact+Form+Info",
          required: true,
          type: "textarea",
        },
        {
          id: "v5",
          question:
            "Receive updates and tour invites? (Sent roughly once a month.)",
          fieldName: "Email+Opt+In",
          required: true,
          type: "radio",
          answers: ["Yes", "No"],
          defaultAnswer: "Yes",
        },
      ],
    },
  }

  const validateQuestionCondition = (question, data) =>
    question.conditionQuestion
      ? question.conditionAnswers.includes(
          get(data ? data : watches, `form._${question.conditionQuestion}`)
        )
      : true

  const currentQuestions = get(routes, `${stepState.personType}.questions`, [])
    .map(question => (validateQuestionCondition(question) ? question : null))
    .slice(0, stepState.step >= 0 ? stepState.step + 1 : 0)
  const lastCurrentQuestion = currentQuestions[currentQuestions.length - 1]
  const isLastQuestion = stepState.personType
    ? routes[stepState.personType].questions[
        routes[stepState.personType].questions.length - 1
      ] === lastCurrentQuestion
    : false
  React.useEffect(() => {
    const sendForm = async data => {
      const formId = "4970810167066624"
      const formName = "Master+Form+(2020 Redesign)"
      let queryString =
        "?cors=true&_agile_redirect_url=%23&_agile_document_url=&_agile_confirmation_msg=https%3A%2F%2Fwww.plantprefab.com%2F&tags=&_agile_domain=plantprefab"
      queryString += `&_agile_api=qu2mf0ejntqq4vc2j7tli3ctod`
      queryString += `&Lead+Source=Web`
      queryString += `&_agile_form_name=${formName}`
      queryString += `&_agile_form_id=${formId}`
      queryString += `&first_name=${encodeURI(
        data.form.basicDetails.firstName
      )}`
      queryString += `&last_name=${encodeURI(data.form.basicDetails.lastName)}`
      queryString += `&email=${encodeURI(data.form.basicDetails.email)}`
      queryString += `&Lead+Category=${encodeURI(
        routes[stepState.personType].leadCategory
      )}`
      for (const question of currentQuestions) {
        if (question && question.id) {
          const answer = get(data, `form._${question.id}`)
          if (answer && question.type !== "location") {
            queryString += `&${question.fieldName}=${encodeURI(answer)}`
          } else if (answer) {
            queryString +=
              `&state=` + encodeURI(get(data, `form._${question.id}.state`))
            queryString +=
              `&city=` + encodeURI(get(data, `form._${question.id}.city`))
            queryString +=
              `&address=` + encodeURI(get(data, `form._${question.id}.address`))
            queryString +=
              `&country=` + encodeURI(get(data, `form._${question.id}.country`))
            queryString +=
              `&zip=` + encodeURI(get(data, `form._${question.id}.zip`))
          }
          if (question.hiddenFields) {
            for (const field of question.hiddenFields) {
              queryString += field
            }
          }
        }
      }
      const url = "https://plantprefab.agilecrm.com/formsubmit"
      const response = await fetch(url + queryString, {
        method: "GET",
      })
      if (response.status === 200) {
        setStepState({ ...stepState, sending: false, sent: true })
      }
    }
    if (stepState.sending) sendForm(stepState.data)
  }, [stepState, currentQuestions, routes])
  return (
    <Container customStyle={{ ...customStyle }}>
      {stepState.sent ? (
        <Text type="h2">Thank you. We look forward to connecting!</Text>
      ) : stepState.sending ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {renderBasicQuestions()}
          {stepState.basicDetailsSet ? renderPersonTypeSelector() : null}
          {currentQuestions.map((question, index) => (
            <div key={index}>{question && renderQuestion(question)}</div>
          ))}
          {!stepState.basicDetailsSet ? (
            <Button type="submit" variant="forms.buttonChevron">
              Let's talk
            </Button>
          ) : null}
          {stepState.basicDetailsSet && stepState.personType ? (
            <Button type="submit" variant="forms.buttonChevron">
              {isLastQuestion ? "Submit" : "Next question"}
            </Button>
          ) : null}
        </form>
      )}
    </Container>
  )
}

export default ContactForm
